import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BigHeader from "../../components/bigHeader"
import Diagram1v2 from "../../components/diagrams/diagram1v2"
import { Waypoint } from "react-waypoint"
import $ from "jquery"
import { StaticQuery, graphql } from "gatsby"

const TrulySustainAbleFarming = ({ contentField }) => {
  const diagram = () => {
    $(".animated-bar, .animated-text").addClass("active")
  }

  const lang = "es"
  const seo = contentField.seo
  const image = contentField.featuredImage

  return (
    <Layout
      lang={lang}
      translationEN="/truly-sustainable-farming/"
      translationIT="/it/un-autentico-allveamento-sosteinibile/"
      translationDE="/de/wirklich-nachaltige-aufzucht/"
      translationFR="/fr/elevage-veritablement-issu-du-developpement-durable/"
    >
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={image ? image.sourceUrl : "https://hiddenfjord.com/og-image.png"}
      />

      <BigHeader
        title="Piscicultura verdaderamente sostenible"
        image={require("../../assets/images/truly-sustainable-farming-background.jpg")}
      />

      <div className="main-section">
        <div
          className="text-container"
          dangerouslySetInnerHTML={{
            __html: contentField.TrulySustainableFarmingACF.firstText,
          }}
        />
        <div className="text-container">
          <br />
          <Waypoint onEnter={diagram} bottomOffset="35%">
            <div className="diagrams-container">
              <Diagram1v2 lang={lang} />
            </div>
          </Waypoint>
          <br />
          <br />
        </div>
        <div
          className="text-container"
          dangerouslySetInnerHTML={{
            __html: contentField.TrulySustainableFarmingACF.secondText,
          }}
        />
        <div className="text-container">
          <br />
          <p className="text-small">
            {contentField.TrulySustainableFarmingACF.firstChartDescription}
          </p>
        </div>
      </div>
    </Layout>
  )
}
export default () => (
  <StaticQuery
    query={graphql`
      {
        contentField: wpPage(id: { eq: "cG9zdDoxNTk0" }) {
          featuredImage {
            node {
              sourceUrl
            }
          }
          TrulySustainableFarmingACF {
            firstChartDescription
            firstText
            secondText
          }
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    `}
    render={data => <TrulySustainAbleFarming {...data} />}
  />
)
